<template>
  <div class="about">
    <h1>Tech Demo</h1>
    <button role="button" v-on:click="track()">Track Position</button>
    <label for="debug">Position Debug</label><textarea id="debug" v-model="debugTxt"></textarea>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data: function () {
    return {
      errors: [],
      debugTxt: '',
      gettingLocation: false,
      errorStr: null,
      latLng: null
    }
  },
  methods:
    {
      async track (event) {
        this.gettingLocation = true
        try {
          this.gettingLocation = false
          this.latLng = await this.getLocation()

          this.saveGeolocationPosition(this.latLng)

          this.debugTxt += '\r\n' + this.latLng.coords.latitude + '/' + this.latLng.coords.longitude
          console.log(this.latLng)
        } catch (e) {
          this.gettingLocation = false
          this.errorStr = e.message
          this.debugTxt += '\r\n' + this.errorStr
        }
      },
      async getLocation () {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(pos => {
            resolve(pos)
          }, err => {
            reject(err)
          })
        })
      },
      saveGeolocationPosition (latLng) {
        axios.post('/api/track.php', {
          latitude: latLng.coords.latitude,
          longitude: latLng.coords.longitude,
          timestamp: latLng.timestamp,
          heading: latLng.heading,
          accuracy: latLng.coords.accuracy

        })
          .then(response => {
            console.log(response)
          })
          .catch(e => {
            this.errors.push(e)
            console.log(this.errors)
          })
      }
    }

}
</script>
